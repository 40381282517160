import { useEffect } from "react";
import ServicesCards from "../../components/Home/Services/Services";
import ServicesBanner from "../../components/Services/ServicesBanner";
import CTA from "../../components/CTA/CTA";
import FAQ from "../../components/Services/FAQ";

import { motion } from "framer-motion";

function Services() {
  useEffect(() => {
    document.title = "Our Services - Webwise Digital";
  }, []);
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <ServicesBanner />
      <ServicesCards />
      <CTA />
      <FAQ />
    </motion.div>
  );
}

export default Services;
