import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "../src/components/Navbar/Navbar.jsx";
import Footer from "../src/components/Footer/Footer.jsx";
import {
  Home,
  About,
  Services,
  Admin,
  ContactUs,
} from "../src/pages/export.js";
import NoPage from "./components/Nopage/NoPage.jsx";
import Web from "./pages/services/allPages/Web.jsx";
import Seo from "./pages/services/allPages/Seo.jsx";
import VideoEditing from "./pages/services/allPages/VideoEditing.jsx";
import DigitalMarketing from "./pages/services/allPages/DigitalMarketing.jsx";
import GraphicDesign from "./pages/services/allPages/GraphicDesign.jsx";
import UIandUX from "./pages/services/allPages/UiAndUx.jsx";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.jsx";
import Icon from "./components/Icon/Icon.jsx";
import LandingPageAdmin from "./landingpagecomponents/LandingPageAdmin.jsx";
import Hero from "./landingpagecomponents/Hero.jsx";
// google analytics
import ReactGA from "react-ga";
import { useEffect } from "react";

import { AnimatePresence } from "framer-motion";

ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);

function App() {
  const location = useLocation();
  const hideNavbarAndFooter =
    location.pathname === "/admin" ||
    location.pathname === "/landingpage" ||
    location.pathname === "/landingpage/admin";

  useEffect(() => {
    // console.log("Tracking page view for", location.pathname + location.search);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <ScrollToTop />
      {!hideNavbarAndFooter && <Navbar />}
      <AnimatePresence location={location} key={location.pathname}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/web-services" element={<Web />} />
          <Route path="/services/seo-services" element={<Seo />} />
          <Route
            path="/services/video-editing-services"
            element={<VideoEditing />}
          />
          <Route
            path="/services/paid-ads-services"
            element={<DigitalMarketing />}
          />
          <Route
            path="/services/graphic-design-services"
            element={<GraphicDesign />}
          />
          <Route path="/services/ui-and-ux" element={<UIandUX />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/*" element={<NoPage />} />
          <Route path="/landingpage" element={<Hero />} />
          <Route path="/landingpage/admin" element={<LandingPageAdmin />} />
        </Routes>
      </AnimatePresence>
      {!hideNavbarAndFooter && <Footer />}
      {!hideNavbarAndFooter && <Icon />}
    </>
  );
}

export default App;
