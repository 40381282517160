import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import Contact from "../../../components/Contact/Contact";
import { Link } from "react-router-dom";

export default function Seo() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      className="bg-dark font-poppins"
    >
      <TextParallaxContent
        imgUrl="https://res.cloudinary.com/dby6tugob/image/upload/v1725870333/lx9n8l1i10d6agpby851.webp"
        subheading="Let Webwise Digital get your brand-baby, the attention and exposure it deserves.
                    Paid Ads are a great way to go viral and make the most conversions and sales"
        heading="Paid Ads."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="About"
          paragraphs={[
            "At Webwise, our paid ads management services are designed to help your business thrive in the competitive digital landscape. We specialize in creating highly effective, data-driven advertising campaigns across various platforms. From Google Ads to social media advertising, we develop customized ad strategies that align with your business gAt Webwise, our digital marketing services are designed to help your business thrive in the digital landscape.",
            "We offer a comprehensive suite of services that encompass all aspects of digital marketing, from strategy development to execution and analysis. Our team of experts leverages the latest tools and techniques to create customized marketing strategies tailored to your business goals. Whether you aim to increase brand awareness, drive traffic, generate leads, or boost sales, we have the expertise to deliver results.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        subheading="Never compromise."
        heading="Quality"
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Our Mission"
          paragraphs={[
            "At Webwise, our mission is to empower businesses with innovative and results-driven ads management solutions that fuel growth and success. We believe that every business, no matter its size or industry, should have access to powerful advertising tools and expert strategies to thrive in today’s competitive digital landscape.",
            "Our aim is to help you reach your audience effectively, foster meaningful connections, and achieve measurable success in your business objectives.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://images.pexels.com/photos/6476246/pexels-photo-6476246.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        subheading="Transform Your Business with Paid Ads Management"
        heading="Modern"
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Deliverables"
          paragraphs={[
            "When you choose Webwise for your ads management needs, you receive a comprehensive range of deliverables designed to maximize your online visibility and drive measurable results. These include a custom ad strategy tailored to your business goals, campaign setup and management across platforms like Google, Facebook, and Instagram, as well as advanced audience targeting to reach the right customers at the right time.",
            "We also provide continuous optimization to enhance campaign performance and maximize return on ad spend (ROAS), along with detailed performance reports to keep you informed. Our in-depth analysis and insights ensure your strategies are always evolving for even greater success.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <Contact />
    </motion.div>
  );
}

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="text-center text-4xl font-bold md:text-7xl mb-4">
        {heading}
      </p>
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl md:px-32">
        {subheading}
      </p>
    </motion.div>
  );
};

const ExampleContent = ({ title, paragraphs, buttonText, buttonLink }) => (
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="text-white col-span-1 text-3xl font-bold md:col-span-4">
      {title}
    </h2>
    <div className="col-span-1 md:col-span-8">
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="mb-4 text-xl text-neutral-600 md:text-2xl">
          {paragraph}
        </p>
      ))}
      <Link to={buttonLink}>
        <button className="w-full rounded-full bg-darkBlue hover:bg-darkPink px-9 py-4 text-xl text-white transition-colors md:w-fit">
          {buttonText} <FiArrowUpRight className="inline" />
        </button>
      </Link>
    </div>
  </div>
);
