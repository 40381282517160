import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import Contact from "../../../components/Contact/Contact";
import { Link } from "react-router-dom";
export default function Seo() {
  return (
    <motion.div className="bg-dark font-poppins">
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1574717024653-61fd2cf4d44d?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Do you want professional video editing to elevate your content ?"
        heading="Video Editing."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="About"
          paragraphs={[
            "At Webwise, our video editing services are designed to transform your raw footage into compelling, high-quality videos that captivate your audience. Whether you need promotional videos, social media content, corporate presentations, or any other type of video, our skilled editors have the expertise to bring your vision to life. We use the latest editing software and techniques to ensure that your videos are polished, professional, and aligned with your brand’s message and style.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1594394489098-74ac04c0fc2e?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Quality"
        heading="Never compromise."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Our Mission"
          paragraphs={[
            "Our mission at Webwise is to empower businesses with exceptional video content that enhances their digital marketing efforts and strengthens their brand presence. We understand the importance of visual storytelling in today's digital landscape, and our goal is to provide you with video editing services that elevate your content and resonate with your audience.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1531178625044-cc2a0fb353a9?q=80&w=1171&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Modern"
        heading="Elevate Your Content with Professional Video Editing."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Deliverables"
          paragraphs={[
            "When you choose Webwise for your video editing needs, you can expect a comprehensive suite of deliverables designed to ensure your satisfaction and success. ",
            "With Webwise, you can be confident that your video editing project will be handled with the highest level of creativity, professionalism, and attention to detail. Let us help you create videos that not only look great but also drive your business forward.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <Contact />
    </motion.div>
  );
}

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">
        {subheading}
      </p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

const ExampleContent = ({ title, paragraphs, buttonText, buttonLink }) => (
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="text-white col-span-1 text-3xl font-bold md:col-span-4">
      {title}
    </h2>
    <div className="col-span-1 md:col-span-8">
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="mb-4 text-xl text-neutral-600 md:text-2xl">
          {paragraph}
        </p>
      ))}
      <Link to={buttonLink}>
        <button className="w-full rounded-full bg-darkBlue hover:bg-darkPink px-9 py-4 text-xl text-white transition-colors md:w-fit">
          {buttonText} <FiArrowUpRight className="inline" />
        </button>
      </Link>
    </div>
  </div>
);
