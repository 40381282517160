import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import Contact from "../../../components/Contact/Contact";
import { Link } from "react-router-dom";

export default function Web() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      className="bg-dark font-poppins"
    >
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Do you need a website?"
        heading="Web Development."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="About"
          paragraphs={[
            "Webwise is a team of seasoned web developers dedicated to building cutting-edge digital solutions that elevate your brand and drive tangible results. We understand that your website is more than just an online presence—it's a strategic asset that can transform your business. Partner with us and experience the power of a website that not only looks impressive but also delivers measurable value.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://images.pexels.com/photos/1779487/pexels-photo-1779487.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        subheading="Quality"
        heading="Never compromise."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Our Mission"
          paragraphs={[
            "Our mission is to empower businesses with innovative web solutions that drive growth and success. At Webwise, we believe that a well-designed website is a critical component of any digital marketing strategy. We are committed to delivering websites that not only captivate your audience but also provide a robust foundation for your online marketing efforts.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://images.pexels.com/photos/326576/pexels-photo-326576.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        subheading="Modern"
        heading="Websites to boost your Identity."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Deliverables"
          paragraphs={[
            "Webwise provides custom website design, responsive development. We offer robust e-commerce solutions, SEO optimization, and performance enhancements. Security features, analytics integration, and ongoing support ensure your website is secure, fast, and effective. Trust Webwise for a comprehensive, professional web development experience.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <Contact />
    </motion.div>
  );
}

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">
        {subheading}
      </p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

// const ExampleContent = () => (
//   <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
//     <h2 className="text-white col-span-1 text-3xl font-bold md:col-span-4">
//       About
//     </h2>
//     <div className="col-span-1 md:col-span-8">
//       <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
//         At webwise, we believe your website is more important than just
//         enchancing your online presence -it &apos; a digital extension of your
//         brand identity.
//       </p>
//       <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
//         Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
//         reiciendis blanditiis aliquam aut fugit sint.
//       </p>
//       <button className="w-full rounded-full bg-darkBlue hover:bg-darkPink px-9 py-4 text-xl text-white transition-colors md:w-fit">
//         Learn more <FiArrowUpRight className="inline" />
//       </button>
//     </div>
//   </div>
// );

const ExampleContent = ({ title, paragraphs, buttonText, buttonLink }) => (
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="text-white col-span-1 text-3xl font-bold md:col-span-4">
      {title}
    </h2>
    <div className="col-span-1 md:col-span-8">
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="mb-4 text-xl text-neutral-600 md:text-2xl">
          {paragraph}
        </p>
      ))}
      <Link to={buttonLink}>
        <button className="w-full rounded-full bg-darkBlue hover:bg-darkPink px-9 py-4 text-xl text-white transition-colors md:w-fit">
          {buttonText} <FiArrowUpRight className="inline" />
        </button>
      </Link>
    </div>
  </div>
);
