import React, { useState } from "react";
import { MdError } from "react-icons/md";
import { addDocWithoutId } from "../../helpers"; // Adjust the path as necessary

const Contact = () => {
  // Form States
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const optionsArray = [
    { value: "Web Development", label: "Web Development" },
    { value: "SEO", label: "SEO" },
    { value: "Social Media Marketing", label: "Social Media Marketing" },
    { value: "Logo Designing", label: "Logo Designing" },
    { value: "Content Marketing", label: "Content Marketing" },
    { value: "Email Marketing", label: "Email Marketing" },
    { value: "Other", label: "Other" },
  ];

  const [showWarning, setShowWarning] = useState(false);

  const handleFormSubmit = async () => {
    if (
      name.length === 0 ||
      email.length === 0 ||
      phone.length === 0 ||
      selectedOptions.length <= 0
    ) {
      setShowWarning(true);
    } else {
      setShowWarning(false);

      let data = {
        name: name,
        email: email,
        phone: phone,
        message: message,
        service: selectedOptions,
      };
      await addDocWithoutId(data);
      clearFormValues();
      alert("Form Submitted");
    }
  };

  const clearFormValues = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setSelectedOptions([]);
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    const isSelected = event.target.checked;

    if (isSelected) {
      // Add the selected option to the array
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        selectedValue,
      ]);
    } else {
      // Remove the unselected option from the array
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((option) => option !== selectedValue)
      );
    }
  };

  return (
    <div>
      <div className="font-poppins bg-dark p-4 flex flex-col">
        <div className="p-8 text-white ">
          <h1 className="font-bold text-2xl md:text-3xl lg:text-5xl">
            Shall we Chat?
          </h1>
          <h1 className="text-xl md:text-2xl lg:text-4xl">
            {" "}
            Let&apos;s talk about your business!{" "}
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-center">
          <div className="pt-4 md:pt-10">
            <img
              src="https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="Contact Us"
              className="lg:h-[500px] rounded-lg"
            />
            <h1 className="p-3 text-white">
              Please tell us about your digital needs, and we will connect with
              you as soon as possible.
            </h1>
          </div>
          {/* form */}
          <div className="text-white flex flex-col lg:flex-row lg:pl-10 lg:relative bottom-14">
            <div className="shadow-lg shadow-gray-100">
              <div className="overlay_styles" />
              <div className="modal_styles">
                <div className="modalContent">
                  <div className="p-4">
                    <h2 className="p-2 text-2xl font-bold pb-2 text-center">
                      Request A Free Proposal.
                    </h2>
                    {showWarning && (
                      <div className="flex justify-between bg-red-600 p-2 text-sm">
                        <p>All fields are required to proceed!</p>
                        <MdError size={20} />{" "}
                      </div>
                    )}
                    <p className="modalLabel">
                      Name<span className="redSign">*</span>
                    </p>

                    <input
                      type="text"
                      className="px-3 py-2 rounded-md text-black outline-none w-full"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <p className="modalLabel">
                      Phone Number<span className="redSign">*</span>
                    </p>
                    <input
                      type="text"
                      className="px-3 py-2 rounded-md text-black outline-none w-full"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <p className="modalLabel">
                      Email<span className="redSign">*</span>
                    </p>
                    <input
                      type="email"
                      className="px-3 py-2 rounded-md text-black outline-none w-full"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <p className="modalLabel">Message</p>
                    <input
                      type="text"
                      className="px-3 py-2 rounded-md text-black outline-none w-full"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />

                    <div className="pt-6">
                      <h3 className="text-xl">
                        What services can we provide you?
                        <span className="redSign">*</span>
                      </h3>
                      <div className="grid grid-cols-2 gap-4 pt-2">
                        {optionsArray.map((option, index) => (
                          <div key={index} className="flex gap-2">
                            <input
                              type="checkbox"
                              name="option"
                              value={option.value}
                              onChange={handleOptionChange}
                              checked={selectedOptions.includes(option.value)}
                              className="w-[10%]"
                            />
                            <p>{option.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <br />
                    <br />
                    <div className="flex justify-between">
                      <button
                        onClick={handleFormSubmit}
                        className="bg-darkBlue text-xl p-3 rounded-full uppercase font-semibold hover:bg-darkPink"
                      >
                        Submit
                      </button>

                      <button
                        onClick={clearFormValues}
                        className="bg-darkPink text-xl p-3 rounded-full uppercase font-semibold hover:bg-darkBlue"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
