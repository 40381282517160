import React, { useState } from "react";
import { motion } from "framer-motion";

import Service from "./Service";

const services = [
  {
    title: "Vanilla Web Development",
    content:
      "Building websites from scratch using core web technologies like HTML, CSS, and JavaScript without frameworks.",
    value: "vanilla",
  },
  {
    title: "Wordpress Web Development",
    content:
      "Creating and customizing websites using the WordPress content management system, leveraging themes and plugins for functionality.",
    value: "wordpress",
  },
  {
    title: "Custom Websites",
    content:
      "Developing tailored websites with unique designs and features, often built from the ground up to meet specific client needs.",
    value: "react",
  },
  {
    title: "Ecommerce Websites",
    content:
      "Designing and developing online platforms optimized for buying and selling products or services, featuring secure payment gateways and user-friendly shopping experiences.",
    value: "ecommerce",
  },
];

const Services = () => {
  const [currentService, setCurrentService] = useState("vanilla");

  const [showServiceContent, setShowServiceContent] = useState({
    title: "",
    content: "",
  });

  const handleSetCurrentService = (value) => {
    if (currentService == value) {
      setCurrentService("");
      setShowServiceContent({
        title: "",
        content: "",
      });
      return;
    }

    setCurrentService(value);
    let z = services.find((s) => s.value === value);
    setShowServiceContent({
      title: z.title,
      content: z.content,
    });
  };

  return (
    <motion.div
      id="services"
      className="bg-gradient-to-r from-slate-800 to-slate-950 flex flex-col items-center justify-center pt-24 pb-96 px-12"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
    >
      <motion.div
        className="text-white "
        initial={{ x: -1024 }}
        animate={{ x: 0 }}
      >
        <h1 className="text-4xl font-bold">Our Services</h1>
        <p className="text-sm">(click on a service to view more!)</p>
      </motion.div>

      <div className="flex flex-row gap-10 w-full items-start justify-center pt-6 space-x-40">
        <div className="flex flex-col gap-1">
          {services.map((service) => (
            <Service
              handleSetCurrentService={handleSetCurrentService}
              title={service.title}
              icon={service.icon}
              content={service.content}
              value={service.value}
            />
          ))}
        </div>
        <div>
          <div className="text-white">
            <h1 className="font-bold text-3xl">{showServiceContent.title}</h1>
            <p className="text-2xl text-slate-400 w-11/12">
              {showServiceContent.content}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Services;
