import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import Contact from "../../../components/Contact/Contact";
import { Link } from "react-router-dom";

export default function Web() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      className="bg-dark font-poppins"
    >
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1559028012-481c04fa702d?q=80&w=1936&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Do you need a Eye Catchy Design?"
        heading="UI & UX."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="About"
          paragraphs={[
            "At Webwise, our UI (User Interface) and UX (User Experience) services are designed to create engaging, intuitive, and efficient digital experiences for your users. We understand that a well-designed interface and seamless user experience are crucial for keeping visitors engaged and converting them into loyal customers. Our team of skilled designers and UX experts work collaboratively to ensure that your digital products not only look great but also provide an enjoyable and efficient user experience.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1621111848501-8d3634f82336?q=80&w=1930&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Quality"
        heading="Never compromise."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Our Mission"
          paragraphs={[
            "Our mission at Webwise is to empower businesses with exceptional UI and UX design services that create meaningful and enjoyable interactions between users and digital products. We believe that great design is more than just aesthetics; it’s about creating intuitive and efficient experiences that meet the needs of your users and support your business objectives.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1587440871875-191322ee64b0?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Modern"
        heading="Optimize User Experience with Innovative UI/UX Design."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Deliverables"
          paragraphs={[
            "When you choose Webwise for your UI and UX design needs, you receive a comprehensive suite of deliverables designed to enhance your digital products and improve user satisfaction.",
            "With Webwise, you can be confident that your UI and UX design project will be handled with the highest level of creativity, professionalism, and attention to detail. Let us help you create digital products that not only look great but also provide an exceptional user experience that drives business success.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <Contact />
    </motion.div>
  );
}

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">
        {subheading}
      </p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

// const ExampleContent = () => (
//   <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
//     <h2 className="text-white col-span-1 text-3xl font-bold md:col-span-4">
//       About
//     </h2>
//     <div className="col-span-1 md:col-span-8">
//       <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
//         At webwise, we believe your website is more important than just
//         enchancing your online presence -it &apos; a digital extension of your
//         brand identity.
//       </p>
//       <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
//         Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
//         reiciendis blanditiis aliquam aut fugit sint.
//       </p>
//       <button className="w-full rounded-full bg-darkBlue hover:bg-darkPink px-9 py-4 text-xl text-white transition-colors md:w-fit">
//         Learn more <FiArrowUpRight className="inline" />
//       </button>
//     </div>
//   </div>
// );

const ExampleContent = ({ title, paragraphs, buttonText, buttonLink }) => (
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="text-white col-span-1 text-3xl font-bold md:col-span-4">
      {title}
    </h2>
    <div className="col-span-1 md:col-span-8">
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="mb-4 text-xl text-neutral-600 md:text-2xl">
          {paragraph}
        </p>
      ))}
      <Link to={buttonLink}>
        <button className="w-full rounded-full bg-darkBlue hover:bg-darkPink px-9 py-4 text-xl text-white transition-colors md:w-fit">
          {buttonText} <FiArrowUpRight className="inline" />
        </button>
      </Link>
    </div>
  </div>
);
