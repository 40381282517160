import React from "react";
import { Link } from "react-router-dom";
// import BannerImage from '../../../assests/Banner.png';

export default function NewHomeSection() {
    return (
        <div 
            className='h-screen w-full bg-cover bg-center p-20 font-poppins'
            style={{ backgroundImage: "url(./Banner.png)" } }
        >
            <div className="font-poppins flex flex-col items-center justify-center h-full gap-2">
                <h1 className="mb-2 text-4xl lg:text-8xl  font-bold text-center text-white">
                Building brands 
                </h1>
                <p className="text-lg md:text-2xl lg:text-4xl  text-center text-white">
                with purpose and passsion
                </p>
                <div className="mt-4">
                    <Link to={'/contactus'} className="px-6 py-3 text-center text-white bg-dark hover:bg-pinkLigth hover:text-black rounded-md shadow-md">
                        Get started
                    </Link>
                </div>
            </div>
        </div>
    );
}
