import React from "react";
import {
  FaCode,
  FaSearch,
  FaVideo,
  FaBehance,
  FaFigma,
  FaBullhorn,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const servicesData = [
  {
    icon: <FaCode />,
    title: "Web Development",
    description:
      "We provide a clean and professional website which is necessary to make a successful business. Our custom website design and development services help in conveying your business message clearly. Also, we design websites according to your business requirements and convert your imaginary ideas into reality.",
    delay: "0.1s",
    link: "/services/web-services",
  },
  {
    icon: <FaSearch />,
    title: "SEO Services",
    description:
      "We provide best SEO services in Delhi and our area of SEO services includes SEO Plan, SEO Consultancy, Dedicated SEO resources, SEO Advisory. We assist you with affordable SEO services which helps you to check the pages with errors, problems in on-page optimization, internal link text, duplicate content & many more criteria.",
    delay: "0s",
    link: "/services/seo-services",
  },
  {
    icon: <FaVideo />,
    title: "Video Editing",
    description:
      "We offer a diverse range of video services tailored to meet your specific needs. Our services include animated explainer videos, corporate videos, promotional videos, product demos, and much more. Whether you're looking to engage your audience, boost brand awareness, our versatile video services have you covered.",
    delay: "0.3s",
    link: "/services/video-editing-services",
  },
  {
    icon: <FaBullhorn />,
    title: "Paid Ads",
    description:
      "We professionally manage your Paid Advertising by making every click count.We are experts in driving targeted traffic to boost online sales and revenue. Our team uses the latest strategies to deliver you its most benefits in your unique budget.",
    delay: "0s",
    link: "/services/paid-ads-services",
  },
  {
    icon: <FaBehance />,
    title: "Graphic Design",
    description:
      "We provide a perfect look to the entire design process and serve as a one-stop solution for all your graphic design needs. Whether it's your website, logos, letterheads, or envelopes, we design everything according to your requirements and industry type.",
    delay: "0.1s",
    link: "/services/graphic-design-services",
  },
  {
    icon: <FaFigma />,
    title: "UI/UX",
    description:
      "We offer you the best of UI/UX design services and solutions, mainly to deliver a positive experience. We offer you better designing services, mainly to bridge the gap between your goals and client expectations efficiently, ensuring satisfaction and engagement.",
    delay: "0.3s",
    link: "/services/ui-and-ux",
  },
];

function Services() {
  return (
    <div
      id="services"
      className="section relative pt-20 pb-8 md:pt-16 md:pb-0  font-poppins bg-dark"
    >
      <div className="container xl:max-w-6xl mx-auto px-4">
        <header className="text-center mx-auto mb-12 lg:px-20">
          <h2 className="text-3xl leading-normal mb-2 font-bold text-white md:text-4xl lg:text-5xl">
            What We Do
          </h2>
          <p className="text-white leading-relaxed font-light text-xl mx-auto pb-2">
            We Make Your Business An Online Success!
          </p>
        </header>
        <div className="flex flex-wrap flex-row -mx-4 text-center">
          {servicesData.map((service, index) => (
            <div
              key={index}
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay={service.delay}
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: service.delay,
                animationName: "fadeInUp",
              }}
            >
              <Link to={service.link}>
                <div className="py-8 px-12 mb-12  border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2 bg-[#B96B85] shadow-lg shadow-white rounded-lg">
                  <div className="inline-block mb-4 text-3xl text-white">
                    {service.icon}
                  </div>
                  <h3 className="text-lg leading-normal mb-2 font-semibold text-black">
                    {service.title}
                  </h3>
                  <p className="text-black-500">{service.description}</p>

                  <button className="text-gray-400 hover:text-white ease-in-out">
                    Learn more
                  </button>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
