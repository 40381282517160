import React, { useState } from "react";

import { motion } from "framer-motion";

import { LPAddEmail } from "../helpers";

const QuickContact = () => {
  const [email, setEmail] = useState("");

  const handleEmailForm = async (e) => {
    e.preventDefault();
    try {
      const res = await LPAddEmail({ email: email });
      setEmail("");
      alert("SENT!");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleEmailForm}>
      <div className="flex flex-col gap-6 py-24 items-center justify-center text-black">
        <h1 className="font-bold text-4xl">
          Send us your Email for a Quick Chat!
        </h1>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder="john@gmail.com"
          className="input border-2 rounded-lg w-3/5 h-12 px-3"
        />
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          type="submit"
          className="bg-black text-white px-6 py-3 rounded-xl hover:bg-gray-800"
        >
          Send
        </motion.button>
      </div>
    </form>
  );
};

export default QuickContact;
