import React from "react";
import { Link } from "react-router-dom";
import ServicesBannerImage from "../../assests/ServicesBanner.jpg";

function ServicesBanner() {
  return (
    <div
      className="font-poppins pt-20 bg-cover bg-center h-screen"
      style={{ backgroundImage: `url(${ServicesBannerImage})` }}
    >
      <div className="flex items-center h-screen">
        <div className="container relative justify-center px-4 mx-auto text-center">
          <h1 className="inline-block mb-8 font-semibold text-gray-100 text-3xl md:text-4xl lg:text-7xl dark:text-gray-300">
            {" "}
            Everything you Need to Succeed Online.
          </h1>
          <h2 className="mb-8 text-xl font-semibold text-gray-100 lg:text-4xl dark:text-gray-400">
            We are Webwise and we are "The Digital Agency in India"
          </h2>
          <p className="mb-8 text-xl text-gray-200 dark:text-gray-400">
            Services carefully crafted to make your digital assets look as
            awesome as possible while leveraging the years of experience in
            online customer engagement and conversion
          </p>
          <div className="flex flex-wrap items-center justify-center">
            <Link
              to="https://wa.me/919953775715"
              className="w-full px-8 py-4 mb-4 mr-0 text-base font-medium text-gray-100 uppercase bg-[#005067] rounded-full lg:w-auto hover:bg-[#B96B85] lg:mb-0 lg:mr-4 md:w-full "
            >
              Let&apos;s Chat
            </Link>
            <a
              href="tel:+919953775715"
              className="w-full px-8 py-4 text-base font-medium text-gray-100 uppercase border border-blue-200 rounded-full dark:border-gray-500 dark:hover:border-pink-800 dark:text-gray-400 lg:w-auto hover:text-gray-200 dark:hover:text-gray-200 hover:border-pink-800 md:w-full hover:bg-[#B96B85]"
            >
              Let&apos;s Talk
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesBanner;
