import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import Contact from "../../../components/Contact/Contact";
import { Link } from "react-router-dom";

export default function Seo() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      className="bg-dark font-poppins"
    >
      <TextParallaxContent
        imgUrl="https://wallpapercave.com/wp/wp5459880.jpg"
        subheading="Do you want huge trafic on your website?"
        heading="Seo Services."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="About"
          paragraphs={[
            "At Webwise, our SEO services are designed to enhance your online visibility and drive targeted traffic to your website. Search Engine Optimization (SEO) is a critical component of any digital marketing strategy, and our team of experts is dedicated to helping your business rank higher on search engine results pages (SERPs). By employing the latest SEO techniques and best practices, we ensure that your website is optimized to attract, engage, and convert potential customers.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://www.aryausa.com/blog/wp-content/uploads/2020/02/seo_1_1024x576.jpg"
        subheading="Quality"
        heading="Never compromise."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Our Mission"
          paragraphs={[
            "Our mission at Webwise is to empower businesses by enhancing their online presence through effective SEO strategies. We believe that a well-optimized website is essential for reaching a wider audience and achieving sustained growth. Our goal is to help you climb the search engine rankings, increase organic traffic, and generate more leads and sales",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <TextParallaxContent
        imgUrl="https://th.bing.com/th/id/OIP.Bw6UVOr-Yadtx3o9sR8UagHaFc?rs=1&pid=ImgDetMain"
        subheading="Modern"
        heading="Enhance Your Online Presence with Effective SEO Strategies."
      >
        {/* <ExampleContent /> */}
        <ExampleContent
          title="Deliverables"
          paragraphs={[
            "When you choose Webwise for your SEO needs, you receive a comprehensive suite of deliverables designed to maximize your online visibility and performance. Our services include detailed keyword research to identify the most effective terms for your business, on-page optimization to ensure your content is search-engine friendly, and high-quality content creation to engage and inform your audience.",
          ]}
          buttonText="Learn more"
          buttonLink="/contactus"
        />
      </TextParallaxContent>
      <Contact />
    </motion.div>
  );
}

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div
        className="absolute inset-0 bg-neutral-950/70"
        style={{
          opacity,
        }}
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">
        {subheading}
      </p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

const ExampleContent = ({ title, paragraphs, buttonText, buttonLink }) => (
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="text-white col-span-1 text-3xl font-bold md:col-span-4">
      {title}
    </h2>
    <div className="col-span-1 md:col-span-8">
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="mb-4 text-xl text-neutral-600 md:text-2xl">
          {paragraph}
        </p>
      ))}
      <Link to={buttonLink}>
        <button className="w-full rounded-full bg-darkBlue hover:bg-darkPink px-9 py-4 text-xl text-white transition-colors md:w-fit">
          {buttonText} <FiArrowUpRight className="inline" />
        </button>
      </Link>
    </div>
  </div>
);
