import React from "react";
import { motion } from "framer-motion";

// Icons
import { FaHtml5, FaWordpress, FaReact } from "react-icons/fa";
import { CiShop } from "react-icons/ci";

const Service = ({ handleSetCurrentService, title, icon, content, value }) => {
  return (
    <motion.div
      className="border-2 border-whitepx-3 px-3 py-2 rounded-lg w-96 cursor-pointer"
      onClick={() => {
        handleSetCurrentService(value);
      }}
      whileHover={{ scale: 1.1, rotateY: -2, rotateZ: -2 }}
      whileTap={{ scale: 0.9, rotateY: 0, rotateZ: 0 }}
    >
      <div className="flex flex-row gap-1 items-center">
        {value == "vanilla" && <FaHtml5 className="text-white text-xl" />}
        {value == "react" && <FaReact className="text-white text-xl" />}
        {value == "ecommerce" && <CiShop className="text-white text-xl" />}
        {value == "wordpress" && <FaWordpress className="text-white text-xl" />}
        <h1 className="text-xl text-white font-semibold">{title}</h1>
      </div>
    </motion.div>
  );
};

export default Service;
