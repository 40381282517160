import { motion, useInView } from "framer-motion";
import { useRef } from "react";
const AboutBanner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div className="bg-dark">
      <motion.div
        style={{
          rotateY: isInView ? "10deg" : "-90deg",
        }}
        className="max-w-[1440px] mx-auto "
        ref={ref}
      >
        <motion.div
          style={{
            transform: isInView ? "none" : "translateY(-100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          className="text-white font-bold text-4xl lg:text-5xl xl:text-7xl pt-32 pb-20 px-4 "
        >
          Welcome to webwisedigital <br />
          <span className="text-[#B96B85]">
            {" "}
             We prioritize quality over quantity
          </span>
        </motion.div>
      </motion.div>
      <div className="z-10">
        <img
          src="https://images.pexels.com/photos/2962135/pexels-photo-2962135.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="About Us"
          className="xl:w-full lg:h-full"
        />
      </div>
    </div>
  );
};

export default AboutBanner;
