import { useEffect } from "react";
import Contact from "../../components/Contact/Contact";
import CTA from "../../components/CTA/CTA";

import { motion } from "framer-motion";

function ContactUs() {
  useEffect(() => {
    document.title = "Contact us - Webwise Digital";
  }, []);
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <div className="pt-16 md:pt-20 lg:pt-24">
        <Contact />
      </div>
      <CTA />
    </motion.div>
  );
}

export default ContactUs;
