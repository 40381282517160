import React, { useEffect } from "react";

import { motion } from "framer-motion";

const Navbar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div className="bg-gradient-to-r from-slate-900 to-slate-800 flex flex-row p-6 sticky top-0">
      <motion.div
        className="text-2xl font-bold dela-gothic-one-regular text-white mr-auto cursor-pointer"
        initial={{ x: -500, opacity: 0, scale: 0.3 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        whileInView={{
          x: [0, 3, 3, 0, -3, -3, 0],
          transition: { repeat: Infinity },
        }}
        whileHover={{ x: 0 }}
      >
        <a href="#">Webwise Digital</a>
      </motion.div>
      <motion.div className="gap-5 mr-12 flex flex-row text-white items-center">
        <a href="#about">
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="cursor-pointer hover:text-slate-500"
          >
            About Us
          </motion.div>
        </a>
        <a href="#services">
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="cursor-pointer hover:text-slate-500"
          >
            Services
          </motion.div>
        </a>
        <a href="#testimonials">
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="cursor-pointer hover:text-slate-500"
          >
            Testimonials
          </motion.div>
        </a>
        <a href="#contact">
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="cursor-pointer hover:text-slate-500"
          >
            Contact
          </motion.div>
        </a>
      </motion.div>
    </motion.div>
  );
};

export default Navbar;
