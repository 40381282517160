import { useEffect } from "react";
import HeroSection from "../../components/Home/Hero/HeroSection";
import AfterHeroSection from "../../components/Home/Hero/AfterHeroSection";
import Test from "../../components/Home/Experience/Test";
import Contact from "../../components/Contact/Contact";
// import Stats from "../../components/Home/Stats/Stats";
import Services from "../../components/Home/Services/Services";
import TestimonialList from "../../components/Home/Testimonials/TestimonialList ";
import Teams from "../../components/Home/Teams/Teams";
import NewHomeSection from "../../components/Home/Hero/NewHomeSection";

import { motion } from "framer-motion";

function Home() {
  useEffect(() => {
    document.title = "Home - Webwise Digital";
  }, []);
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      {/* <HeroSection /> */}
      <NewHomeSection />
      <AfterHeroSection />

      {/* Video section */}
      <Test />

      {/* Services */}
      <Services />

      {/* Company Stats  */}
      {/* <Stats /> */}

      {/* Teams */}
      <Teams />

      {/* Testimonials */}
      <TestimonialList />

      {/* Contact */}
      <Contact />
    </motion.div>
  );
}

export default Home;
