import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Ticker from "./Ticker";
const Description = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      <motion.div
        ref={ref}
        style={{
          background: isInView ? "black" : "white",
          color: "white",
          transition: "all 1s",
        }}
        className="font-poppins"
      >
        <div
          className="max-w-5xl mx-auto px-4 space-y-6 pt-[7cqw]"
          style={{
            transform: isInView ? "" : "translateY(50px)",
            transition: "all 1s",
          }}
        >
          <h2 className="tracking-tight font-bold text-5xl lg:text-6xl xl:text-[4.25rem] leading-snug">
            Webwise Digital Agency
            <span className="text-darkPink ml-3">
              Igniting Excellence Through Dedication &amp; Innovation
            </span>
          </h2>
          <div className="text-xl xl:text-2xl text-gray-400  leading-relaxed space-y-6">
            <p>
              At Webwise Digital, our journey is fired by passion—our core spark. The
              secret to our innovation and success? It&apos;s the fusion of
              relentless dedication, a heart that beats for design, and a drive
              to innovate!
            </p>
            <p className="text-gray-400 mb-65">
              Also, in our marketing and design agency we treasure integrity and
              transparency. Our commitment to ethical practices has been a
              driving force. Clients rely on us for clear communication, honest
              collaboration, and reliable results. This foundation of trust has
              been key to our journey, fostering relationships and fueling our
              enduring success.
            </p>
          </div>
          <div className="mt-4 xl:mt-12 flex justify-start ">
            <Link to={"/contactus"}>
              <button className="p-4 bg-darkBlue rounded-full text-white hover:bg-[#B96B85] hover:animate-pulse">
                Contact US
              </button>
            </Link>
          </div>
        </div>
        <Ticker />
      </motion.div>
    </>
  );
};

export default Description;
