import React from "react";

import { placeholderImage } from "../images/index";

import { motion } from "framer-motion";

const About = () => {
  return (
    <>
      <div
        id="about"
        className="w-full pt-40 mt-0  bg-gradient-to-r from-slate-800 to-slate-950 flex flex-col justify-center items-center"
      >
        <motion.div
          initial={{ opacity: 0, x: -1024 }}
          animate={{ opacity: 1, x: 0 }}
          className="flex flex-col items-center justify-center"
        >
          <h1 className="font-bold text-white text-4xl">About Us</h1>
          <h1 className="text-3xl text-gray-300 px-32">
            We Build stunning, responsive websites with ease using our
            cutting-edge web development tools. Transform your ideas into
            reality with our powerful, user-friendly platform.
          </h1>
        </motion.div>
        <div className="grid grid-rows-2 grid-flow-col gap-4 w-full px-24 mt-20">
          <motion.div whileHover={{ scale: 1.1 }}>
            <img
              src={placeholderImage}
              width={500}
              className="hover:rounded-lg"
            />
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <img
              src={placeholderImage}
              width={500}
              className="hover:rounded-lg"
            />
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <img
              src={placeholderImage}
              width={500}
              className="hover:rounded-lg"
            />
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <img
              src={placeholderImage}
              width={500}
              className="hover:rounded-lg"
            />
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <img
              src={placeholderImage}
              width={500}
              className="hover:rounded-lg"
            />
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <img
              src={placeholderImage}
              width={500}
              className="hover:rounded-lg"
            />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default About;
