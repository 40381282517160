import React from "react";

import { motion } from "framer-motion";

const testimonials = [
  {
    author: "Rohit S.",
    location: "Mumbai",
    content:
      "Choosing Webwise Digital for our website redesign was the best decision we made! They delivered a stunning, responsive site that perfectly represents our brand. Their team was professional, efficient, and always ready to help with any queries.",
  },
  {
    author: "Priya M.",
    location: "Delhi",
    content:
      "Webwise Digital transformed our outdated website into a modern, user-friendly platform. The team's attention to detail and understanding of our needs were exceptional. I highly recommend them for any web development project!",
  },
  {
    author: "Amit K.",
    location: "Bangalore",
    content:
      "We needed a custom e-commerce website with specific functionalities, and Webwise Digital nailed it! The site is fast, secure, and easy to manage. Their support team is also incredibly responsive and helpful.",
  },
  {
    author: "Sneha P.",
    location: "Hyderabad",
    content:
      "From concept to launch, Webwise Digital made the whole web development process seamless. They took our ideas and turned them into a beautifully designed website that exceeded our expectations. Great job, team!",
  },
  {
    author: "Vikram R.",
    location: "Chennai",
    content:
      "Working with Webwise Digital was a pleasure! Their creativity and technical expertise were evident throughout the project. Our website looks fantastic, and we’ve seen a significant increase in traffic since the launch!",
  },
  {
    author: "Ananya T.",
    location: "Kolkata",
    content:
      "Webwise Digital’s team went above and beyond to create a website that truly reflects our brand's personality. Their innovative approach and commitment to quality make them a top choice for web development in India.",
  },
];

const Testimonials = () => {
  return (
    <div className="px-12 pt-24 pl-32 bg-gradient-to-r from-slate-900 to-slate-800 space-y-2 pb-24">
      <h1 className="font-bold text-4xl text-white">Testimonials</h1>
      <motion.div
        initial={{ x: -1024 }}
        animate={{ x: 0 }}
        id="testimonials"
        className="  grid grid-rows-2 grid-flow-col gap-4 "
      >
        {testimonials.map((t) => (
          <motion.div
            initial={{ x: -1024 }}
            animate={{ x: 0 }}
            whileHover={{ rotateX: 5, rotateY: 15 }}
            key={t.author}
            className="p-6 text-white text-2xl font-semibold container border-2 border-slate-700 rounded-lg w-96"
          >
            <h1>{t.author}</h1>
            <p className="text-sm font-light">({t.location})</p>
            <p className="text-lg font-light">{t.content}</p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Testimonials;
