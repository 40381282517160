import Avatar from '../../../assests/userImage.jpg'

const testimonials = [
  {
    name: "Ritika",
    title: "Owner & Director",
    image: Avatar,
    text: "I engaged webwise to make 14 pages of changes to my website! They completed all the tasks very quickly and to a very high standard.",
  },
  {
    name: "Smith",
    title: "Managing Director",
    image:
      Avatar,
    text: "webwise has helped us optimize the sales and marketing processes, and this is automating a lot of processes.",
  },
  {
    name: "Jatin Ahuja",
    title: "Senior Manager",
    image:Avatar,
    text: "We contracted webwise to build our new website. And let me tell you, they did a fantastic job. Their team was really easy to communicate with",
  },
];

const Testimonial = ({ name, title, image, text }) => (
  <div className="bg-[#B96B85] shadow-md shadow-white rounded-lg p-6 max-w-sm mx-auto font-poppins ">
    <div className="flex items-center mb-4">
      <img className="w-16 h-16 rounded-full mr-4" src={image} alt={name} />
      <div>
        <h2 className="text-xl font-semibold">{name}</h2>
        <p className="text-slate-300">{title}</p>
      </div>
    </div>
    <p className="text-gray-200">{text}</p>
  </div>
);

const TestimonialList = () => (
  <div className=" mx-auto p-8 bg-dark w-full">
    <h1 className="font-poppins text-2xl font-bold text-center mb-8  md:text-4xl lg:text-5xl text-white">
      What Our Clients Are Saying Globally.
    </h1>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {testimonials.map((testimonial, index) => (
        <Testimonial key={index} {...testimonial} />
      ))}
    </div>
  </div>
);

export default TestimonialList;
