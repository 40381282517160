import React from "react";

import { team, cloudHosting } from "../images/index";

import { motion } from "framer-motion";
import Navbar from "./Navbar";
import About from "./About";
import Services from "./Services";
import Testimonials from "./Testimonials";
import QuickContact from "./QuickContact";
import Contact from "./Contact";
import Icon from "./Icon";

const Hero = () => {
  return (
    <>
      <Navbar />
      <div className=" bg-gradient-to-r from-slate-900 to-slate-800 w-full h-screen pt-20">
        <div className="flex flex-row gap-56 items-center justify-center">
          {/* Text */}
          {/* Image */}
          <div>
            <motion.div
              className="flex flex-col gap-2 items-start"
              animate={{ x: 0 }}
              initial={{ x: -500 }}
              transition={{ duration: 0.3 }}
              whileHover={{ rotateY: -30, rotateX: -15, scale: 1.1 }}
            >
              <h1 className="text-6xl text-white font-bold dela-gothic-one-regular">
                Building Brands
              </h1>
              <p className="text-3xl text-slate-400">
                With Purpose And Passion
              </p>
            </motion.div>
            <div className="mt-6 flex flex-col items-start">
              <motion.button
                className="bg-indigo-950 text-white px-6 py-3 rounded-lg drag hover:border-2 hover:border-white"
                whileDrag={{ scale: 0.9, rotateY: 0, rotateX: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                drag
                dragConstraints={{
                  top: 100,
                  left: 50,
                  right: 300,
                  bottom: 100,
                }}
                whileHover={{ scale: 1.3, rotateY: -30, rotateX: -15 }}
                whileTap={{ scale: 0.9 }}
                dragElastic={0.5}
                dragTransition={{ bounceStiffness: 300, bounceDamping: 20 }}
                onDragEnd={(event, info) => {
                  info.point.x = 0;
                  info.point.y = 0;
                }}
              >
                Learn More!
              </motion.button>
              <p className="text-white">(Hold and Drag!)</p>
            </div>
          </div>
          <div>
            <motion.img
              src={cloudHosting}
              className="w-10/12"
              whileHover={{ rotateY: 30, rotateX: 15 }}
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom--5 left-0 right-0 h-12 bg-gradient-to-b from-blue-500 to-transparent"></div>
      <QuickContact />
      <About />
      <Services />
      <Testimonials />
      <Contact />
      <Icon />
    </>
  );
};

export default Hero;
