import React from "react";

function AfterHeroSection() {
  return (
    <div className="bg-dark p-4 flex flex-col lg:flex-row ">
      <div className="p-6 ">
        <div className="flex flex-col items-center justify-center lg:items-start">
          <h1 className="mt-16 font-poppins font-semibold text-xl md:3xl lg:4xl text-gray-100">
            If you are not using digital marketing services for your business
            yet, you might as well be advertising via a carrier pigeon! Jokes
            aside, partnering with a digital marketing agency today is no longer
            optional, it&apos;s a necessity.
          </h1>
        </div>
      </div>
      <div className="p-6">
        <img
          src="https://images.pexels.com/photos/6476260/pexels-photo-6476260.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt=""
          className="rounded-xl mx-auto"
        />
      </div>
    </div>
  );
}

export default AfterHeroSection;
