import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const faqItems = [
  {
    id: 1,
    question: "How can I get started with Webwise digital?",
    answer:
      "Getting started is easy! You can contact us through our website, phone, or email. We'll discuss your needs and connect you with the right team to bring your project to life.",
  },
  {
    id: 2,
    question: "Do you offer custom solutions?",
    answer:
      "Yes, we understand every business is unique. We tailor our services to your specific needs and goals to ensure they deliver the best results.",
  },
  {
    id: 3,
    question: "How will I be involved in the project?",
    answer:
      "We believe in close collaboration. You'll have a dedicated project manager as your point of contact, keeping you informed throughout the process. We also encourage regular communication to ensure we deliver exactly what you envision.",
  },
  {
    id: 4,
    question: "Can I get all your services in one package?",
    answer:
      "Absolutely! We offer services that bundle all our services into a convenient and affordable solution for your business.",
  },
  {
    id: 5,
    question: "Do you offer free consultations?",
    answer:
      "Yes, we offer free consultations to discuss your project requirements and answer any questions you may have about our services. This allows us to understand your goals and suggest the best solutions for your business.",
  },
];

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="font-poppins py-10 sm:py-16 lg:py-24 bg-dark">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-gray-100 sm:text-4xl lg:text-5xl">
            Frequently Asked Questions
          </h2>
          <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-300">
            Welcome to our FAQ section! Here you'll find answers to common
            questions about our digital marketing services
          </p>
        </div>

        <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50"
              onClick={() => toggleAnswer(index)}
            >
              <button
                type="button"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              >
                <span className="text-lg font-semibold text-black">
                  {item.question}
                </span>
                {activeIndex === index ? (
                  <FaChevronUp className="w-6 h-6 text-gray-400" />
                ) : (
                  <FaChevronDown className="w-6 h-6 text-gray-400" />
                )}
              </button>

              {activeIndex === index && (
                <div key={item.id} className="px-4 pb-5 sm:px-6 sm:pb-6">
                  <p>{item.answer} </p>
                </div>
              )}
            </div>
          ))}
        </div>

        <p className="text-center text-gray-300 text-base mt-9">
          Didn&apos;t find the answer you are looking for?{" "}
          <Link
            to={"/contactus"}
            className="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 focus:text-blue-700 hover:underline"
          >
            Contact our support
          </Link>
        </p>
      </div>
    </section>
  );
}

export default FAQ;
