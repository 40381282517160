import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";

// DB Helpers
import { deleteDocument } from "../helpers";

// Styling
import "./lpadmin.css";

const LandingPageAdmin = () => {
  const [messageList, setMessageList] = useState();
  const [userAuthStatus, setUserAuthStatus] = useState(false);

  // Form States
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const ROOT_USER = "admin";
  const ROOT_USER_PASSWORD = "1";

  const handleAdminLogin = () => {
    if (username === ROOT_USER && password === ROOT_USER_PASSWORD) {
      alert("Logged in");
      setUserAuthStatus(true);
    } else {
      alert("Invalid Credentials");
    }
  };

  const fetchData = async () => {
    await getDocs(collection(db, "LANDINGPAGEMESSAGES")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setMessageList(newData);
        try {
          messageList.map((msg) => {
            console.log(msg);
          });
        } catch (err) {
          console.log(err);
        }
      }
    );
  };

  const handleDelete = async (id) => {
    try {
      await deleteDocument("LANDINGPAGEMESSAGES", id);
      fetchData();
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  try {
    return (
      <div className="adminContent">
        {userAuthStatus ? (
          <div className="adminButtonsRow">
            <button
              className="adminRefreshBtn"
              onClick={() => {
                fetchData();
              }}
            >
              Refresh
            </button>

            {messageList.map((msg) => (
              <div className="adminMessage">
                {msg.name && <h3>Sender's Name: {msg.name}</h3>}
                {msg.email && <p>Sender's Email: {msg.email}</p>}
                {msg.message && <p>Sender's Message: {msg.message}</p>}
                {msg.phone && <p>Sender's Phone: {msg.phone}</p>}
                {msg.service && <p>Services:{msg.service}</p>}
                <p>TimeStamp: {msg.timeOfCreation}</p>
                <button
                  className="adminDeleteBtn"
                  onClick={() => {
                    handleDelete(msg.id);
                  }}
                >
                  Delete item
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="adminLoginForm">
            <h1 className="adminLoginFormHeading">LANDING PAGE ADMIN PORTAL</h1>
            <div>
              <p className="adminLoginFormLabel">username:</p>
              <input
                type="text"
                className="adminLoginFormInput"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>
            <div>
              <p className="adminLoginFormLabel">password:</p>
              <input
                type="password"
                className="adminLoginFormInput"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            <button className="adminFormLoginBtn" onClick={handleAdminLogin}>
              Login
            </button>
          </div>
        )}
      </div>

      // <div>admin</div>
    );
  } catch (err) {
    console.log(err);
  }
};

export default LandingPageAdmin;
