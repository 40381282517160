import Ticker from "framer-motion-ticker";
const ImgTicker = () => {
  const images = [
    {
      src: "https://images.pexels.com/photos/2422293/pexels-photo-2422293.jpeg?auto=compress&cs=tinysrgb&w=500&h=300&dpr=1",
      alt: "Work Life at Webwise Agency",
    },
    {
      src: "https://images.pexels.com/photos/3182750/pexels-photo-3182750.jpeg?auto=compress&cs=tinysrgb&w=400&h=550&dpr=1",
      alt: "Breaktime at Webwise",
    },
    {
      src: "https://images.pexels.com/photos/3184325/pexels-photo-3184325.jpeg?auto=compress&cs=tinysrgb&w=500&h=300&dpr=1",
      alt: "Brain Storming at Webwise",
    },
    {
      src: "https://images.pexels.com/photos/57690/pexels-photo-57690.jpeg?auto=compress&cs=tinysrgb&w=400&h=750&dpr=1",
      alt: "Team Work at Webwise Agency",
    },
    {
      src: "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress&cs=tinysrgb&w=500&h=300&dpr=1",
      alt: "Webwise Graphic Design Agency",
    },
    {
      src: "https://images.pexels.com/photos/18024478/pexels-photo-18024478/free-photo-of-mac-studio-computer-and-macbook-pro-laptop-on-a-white-desk.jpeg?auto=compress&cs=tinysrgb&w=400&h=550&dpr=1",
      alt: "The Webwise",
    },
  ];
  return (
    <>
      <div className="flex py-20">
        <div className="flex justify-center overflow-x-hidden">
          <Ticker duration={30}>
            {images?.map((image, index) => (
              <div
                key={index}
                className="flex items-center"
                style={{ marginRight: 60 }}
                data-swiper-slide-index={index}
              >
                <img
                  src={image.src}
                  loading="lazy"
                  sizes={image.sizes}
                  alt=""
                  className="w-full"
                />
              </div>
            ))}
          </Ticker>
        </div>
      </div>
    </>
  );
};

export default ImgTicker;
